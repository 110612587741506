export const CS_1155_NFT_ADDRESS = "0x43dff9458D67f49E8F9BE56c7E6a9Fc8FA2640b3";
export const CS_1155_MINT_ADDRESS =
  "0xf6e2Ab281d605C2E076FFD221D936eb491a66cfb";
export const CS_1155_NFL_ADDRESS = "0xE2b7C9c40978AcC0B2DF9BF4817822B0dEBD43fA";
export const CS_1155_NFL_MINT_ADDRESS =
  "0xf6e2Ab281d605C2E076FFD221D936eb491a66cfb";

export const CS_1155_NFT_ABI =
  require("../components/abi/CryptoSportsNFT.json").abi;
export const CS_1155_MINT_ABI =
  require("../components/abi/CryptoSportsMintingMgmt.json").abi;

export const CS_1155_NFT_NFL_ABI =
  require("../components/abi/CryptoSportsNFT_NFL.json").abi;
export const CS_1155_MINT_NFL_ABI =
  require("../components/abi/CryptoSportsMintingMgmt_NFL.json").abi;

export const BLOCKEXPLORER = "https://fraxscan.com/";

export const TOAST_SETTINGS = {
  style: {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
  },
};

export const OPENSEA_URL =
  "https://fraxscan.com/nft/0xE2b7C9c40978AcC0B2DF9BF4817822B0dEBD43fA/";

export const BLOCK_EXPLORER_URL =
  "https://fraxscan.com/address/0xf6e2Ab281d605C2E076FFD221D936eb491a66cfb";

export const COLLECTION_URL =
  "https://fraxscan.com/address/0xf6e2Ab281d605C2E076FFD221D936eb491a66cfb";

export const DATE_FORMAT = "mm/dd/yyyy";

export const positions = [
  // "Point Guard",
  // "Shooting Guard",
  // "Center",
  // "Power Forward",
  // "Small Forward",
  "Any Player",
  "Any Player",
  "Any Player",
  "Any Player",
  "Any Player",
];

export const MAX_AGE = 200;
export const MIN_AGE = 18;

export const TEAMS = [
  {
    id: 28,
    city: "Los Angeles",
    name: "Clippers",
    label: "Los Angeles Clippers",
    value: "LAC",
  },
  {
    id: 23,
    city: "New Orleans",
    name: "Pelicans",
    label: "New Orleans Pelicans",
    value: "NO",
  },
  {
    id: 5,
    city: "Orlando",
    name: "Magic",
    label: "Orlando Magic",
    value: "ORL",
  },
  {
    id: 3,
    city: "Atlanta",
    name: "Hawks",
    label: "Atlanta Hawks",
    value: "ATL",
  },
  {
    id: 13,
    city: "Indiana",
    name: "Pacers",
    label: "Indiana Pacers",
    value: "IND",
  },
  {
    id: 11,
    city: "Chicago",
    name: "Bulls",
    label: "Chicago Bulls",
    value: "CHI",
  },
  {
    id: 27,
    city: "Los Angeles",
    name: "Lakers",
    label: "Los Angeles Lakers",
    value: "LAL",
  },
  {
    id: 15,
    city: "Milwaukee",
    name: "Bucks",
    label: "Milwaukee Bucks",
    value: "MIL",
  },
  {
    id: 30,
    city: "Sacramento",
    name: "Kings",
    label: "Sacramento Kings",
    value: "SAC",
  },
  {
    id: 4,
    city: "Miami",
    name: "Heat",
    label: "Miami Heat",
    value: "MIA",
  },
  {
    id: 22,
    city: "Houston",
    name: "Rockets",
    label: "Houston Rockets",
    value: "HOU",
  },
  {
    id: 26,
    city: "Golden State",
    name: "Warriors",
    label: "Golden State Warriors",
    value: "GS",
  },
  {
    id: 1,
    city: "Washington",
    name: "Wizards",
    label: "Washington Wizards",
    value: "WAS",
  },
  {
    id: 6,
    city: "New York",
    name: "Knicks",
    label: "New York Knicks",
    value: "NY",
  },
  {
    id: 7,
    city: "Philadelphia",
    name: "76ers",
    label: "Philadelphia 76ers",
    value: "PHI",
  },
  {
    id: 24,
    city: "San Antonio",
    name: "Spurs",
    label: "San Antonio Spurs",
    value: "SA",
  },
  {
    id: 9,
    city: "Boston",
    name: "Celtics",
    label: "Boston Celtics",
    value: "BOS",
  },
  {
    id: 29,
    city: "Phoenix",
    name: "Suns",
    label: "Phoenix Suns",
    value: "PHO",
  },
  {
    id: 12,
    city: "Cleveland",
    name: "Cavaliers",
    label: "Cleveland Cavaliers",
    value: "CLE",
  },
  {
    id: 17,
    city: "Utah",
    name: "Jazz",
    label: "Utah Jazz",
    value: "UTA",
  },
  {
    id: 8,
    city: "Brooklyn",
    name: "Nets",
    label: "Brooklyn Nets",
    value: "BKN",
  },
  {
    id: 10,
    city: "Toronto",
    name: "Raptors",
    label: "Toronto Raptors",
    value: "TOR",
  },
  {
    id: 14,
    city: "Detroit",
    name: "Pistons",
    label: "Detroit Pistons",
    value: "DET",
  },
  {
    id: 2,
    city: "Charlotte",
    name: "Hornets",
    label: "Charlotte Hornets",
    value: "CHA",
  },
  {
    id: 18,
    city: "Oklahoma City",
    name: "Thunder",
    label: "Oklahoma City Thunder",
    value: "OKC",
  },
  {
    id: 20,
    city: "Denver",
    name: "Nuggets",
    label: "Denver Nuggets",
    value: "DEN",
  },
  {
    id: 21,
    city: "Memphis",
    name: "Grizzlies",
    label: "Memphis Grizzlies",
    value: "MEM",
  },
  {
    id: 16,
    city: "Minnesota",
    name: "Timberwolves",
    label: "Minnesota Timberwolves",
    value: "MIN",
  },
  {
    id: 25,
    city: "Dallas",
    name: "Mavericks",
    label: "Dallas Mavericks",
    value: "DAL",
  },
  {
    id: 19,
    city: "Portland",
    name: "Trail Blazers",
    label: "Portland Trail Blazers",
    value: "POR",
  },
];

export const scoreboardLink = "https://sports.yahoo.com/nba/scoreboard";

export const BOOSTERPACK_COUNT = 8;
export const BOOSTERPACK_LOTTO_PACK_TYPE = 1;
export const BOOSTERPACK_ROSTER_PACK_TYPE = 2;
export const SIGN_UP_BOOSTERPACK_TYPE = "SIGN_UP_BOOSTERPACK";
export const DAILY_REWARD = "DAILY_REWARD";

export const LEAGUES = [
  {
    icon: "/images/nba.svg",
    label: "NBA",
    id: "nba",
    disabled: true,
  },
  {
    icon: "/images/nfl.png",
    label: "NFL",
    id: "nfl",
  },
];

export const ABOUT_SITE_URL =
  "https://cryptosports.notion.site/About-the-Game-60bf70613b0849839276636957e41f48";

export const DISCORD_URL = "https://discord.gg/NpAw6nmzbS";

export const NBA_POSITIONS = [
  {
    value: "C",
    label: "C",
  },
  {
    value: "PG",
    label: "PG",
  },
  {
    value: "SG",
    label: "SG",
  },
  {
    value: "SF",
    label: "SF",
  },
  ,
  {
    value: "PF",
    label: "PF",
  },
];

export const NFL_POSITIONS = [
  {
    value: "QB",
    label: "QB",
  },
  {
    value: "RB",
    label: "RB",
  },
  {
    value: "TE",
    label: "TE",
  },
  {
    value: "WR",
    label: "WR",
  },
  {
    value: "FLEX",
    label: "Flex",
  },
  {
    value: "DST",
    label: "D/ST",
  },
];

export const POSITIONS_LIST = {
  nba: NBA_POSITIONS,
  nfl: NFL_POSITIONS,
};

export const CURRENCY: "TOKEN" | "FIAT" = "TOKEN";

export const MONTHLY_PRIZE_LIST = [
  {
    ranks: "1st",
    rewards: "1000 credits",
    icon: "/images/icons/trophy-gold.svg",
  },
  {
    ranks: "2nd",
    rewards: "750 credits",
    icon: "/images/icons/trophy-silver.svg",
  },
  {
    ranks: "3rd",
    rewards: "500 credits",
    icon: "/images/icons/trophy-bronze.svg",
  },
  {
    ranks: "4th",
    rewards: "300 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "5th",
    rewards: "250 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "6th - 9th",
    rewards: "150 credits",
    icon: "/images/icons/trophy-black.svg",
  },
];

export const LEADER_PRIZE_LIST = [
  {
    ranks: "1st",
    rewards: "350 credits",
    icon: "/images/icons/trophy-gold.svg",
  },
  {
    ranks: "2nd",
    rewards: "250 credits",
    icon: "/images/icons/trophy-silver.svg",
  },
  {
    ranks: "3rd",
    rewards: "150 credits",
    icon: "/images/icons/trophy-bronze.svg",
  },
  {
    ranks: "4th",
    rewards: "100 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "5th",
    rewards: "50 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "6th - 9th",
    rewards: "25 credits",
    icon: "/images/icons/trophy-black.svg",
  },
];

export const LEADER_WEEKLY_PRIZE_LIST = [
  {
    ranks: "1st",
    rewards: "350 credits",
    icon: "/images/icons/trophy-gold.svg",
  },
  {
    ranks: "2nd",
    rewards: "250 credits",
    icon: "/images/icons/trophy-silver.svg",
  },
  {
    ranks: "3rd",
    rewards: "150 credits",
    icon: "/images/icons/trophy-bronze.svg",
  },
  {
    ranks: "4th",
    rewards: "100 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "5th",
    rewards: "50 credits",
    icon: "/images/icons/trophy-black.svg",
  },
  {
    ranks: "6th - 9th",
    rewards: "25 credits",
    icon: "/images/icons/trophy-black.svg",
  },
];

export const PLAYER_STATUS = {
  Healthy: {
    symbol: "H",
    color: "#4ED483",
  },
  "Injured Reserve": {
    symbol: "IR",
    color: "#FF4B4B",
  },
  "Physically Unable To Perform": {
    symbol: "O",
    color: "#FF4B4B",
  },
  "Free Agent": {
    symbol: "FA",
    color: "#ABABBB",
  },
  Suspended: {
    symbol: "S",
    color: "#FF4B4B",
  },
  Questionable: {
    symbol: "Q",
    color: "#FCCE08",
  },
};

export const NFL_TEAMS = [
  {
    team: "JAX",
    established: 1993,
    name: "Jacksonville Jaguars",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Jaguars.png",
  },
  {
    team: "SF",
    established: 1970,
    name: "San Francisco 49ers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/49ers.png",
  },
  {
    team: "WAS",
    established: 1932,
    name: "Washington Commanders",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Commanders.png",
  },
  {
    team: "CIN",
    established: 1968,
    name: "Cincinnati Bengals",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bengals.png",
  },
  {
    team: "PIT",
    established: 1933,
    name: "Pittsburgh Steelers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Steelers.png",
  },
  {
    team: "CHI",
    established: 1920,
    name: "Chicago Bears",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bears.png",
  },
  {
    team: "DAL",
    established: 1960,
    name: "Dallas Cowboys",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Cowboys.png",
  },
  {
    team: "MIA",
    established: 1966,
    name: "Miami Dolphins",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Dolphins.png",
  },
  {
    team: "KC",
    established: 1970,
    name: "Kansas City Chiefs",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Chiefs.png",
  },
  {
    team: "NYG",
    established: 1925,
    name: "New York Giants",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Giants.png",
  },
  {
    team: "IND",
    established: 1953,
    name: "Indianapolis Colts",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Colts.png",
  },
  {
    team: "TEN",
    established: 1959,
    name: "Tennessee Titans",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Titans.png",
  },
  {
    team: "CLE",
    established: 1946,
    name: "Cleveland Browns",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Browns.png",
  },
  {
    team: "GB",
    established: 1919,
    name: "Green Bay Packers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Packers.png",
  },
  {
    team: "ATL",
    established: 1965,
    name: "Atlanta Falcons",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Falcons.png",
  },
  {
    team: "SEA",
    established: 1974,
    name: "Seattle Seahawks",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Seahawks.png",
  },
  {
    team: "LV",
    established: 1960,
    name: "Las Vegas Raiders",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Raiders.png",
  },
  {
    team: "DEN",
    established: 1959,
    name: "Denver Broncos",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Broncos.png",
  },
  {
    team: "LAR",
    established: 1936,
    name: "Los Angeles Rams",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Rams.png",
  },
  {
    team: "NYJ",
    established: 1970,
    name: "New York Jets",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Jets.png",
  },
  {
    team: "ARI",
    established: 1898,
    name: "Arizona Cardinals",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Cardinals.png",
  },
  {
    team: "NE",
    established: 1959,
    name: "New England Patriots",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Patriots.png",
  },
  {
    team: "HOU",
    established: 1999,
    name: "Houston Texans",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Texans.png",
  },
  {
    team: "LAC",
    established: 1970,
    name: "Los Angeles Chargers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Chargers.png",
  },
  {
    team: "BAL",
    established: 1996,
    name: "Baltimore Ravens",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Ravens.png",
  },
  {
    team: "CAR",
    established: 1993,
    name: "Carolina Panthers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Panthers.png",
  },
  {
    team: "DET",
    established: 1929,
    name: "Detroit Lions",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Lions.png",
  },
  {
    team: "TB",
    established: 1974,
    name: "Tampa Bay Buccaneers",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Buccaneers.png",
  },
  {
    team: "MIN",
    established: 1961,
    name: "Minnesota Vikings",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Vikings.png",
  },
  {
    team: "NO",
    established: 1967,
    name: "New Orleans Saints",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Saints.png",
  },
  {
    team: "PHI",
    established: 1933,
    name: "Philadelphia Eagles",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Eagles.png",
  },
  {
    team: "BUF",
    established: 1970,
    name: "Buffalo Bills",
    logo: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bills.png",
  },
];

export const PAGE_SIZE = 30;

export const POINTS_RATING = [
  {
    id: "PassingYards",
    pointsPer: 0.04,
  },
  {
    id: "PassingTouchdowns",
    pointsPer: 4,
  },
  {
    id: "PassingInterceptions",
    pointsPer: -2,
  },
  {
    id: "RushingYards",
    pointsPer: 0.1,
  },
  {
    id: "RushingTouchdowns",
    pointsPer: 6,
  },
  {
    id: "Receptions",
    pointsPer: 0.5,
  },
  {
    id: "ReceivingYards",
    pointsPer: 0.1,
  },
  {
    id: "ReceivingTouchdowns",
    pointsPer: 6,
  },
  {
    id: "Touchdowns",
    pointsPer: 6,
  },
  {
    id: "ReceivingTargets",
    pointsPer: 0,
  },
  {
    id: "Sacks",
    pointsPer: 1,
  },
  {
    id: "Interceptions",
    pointsPer: 2,
  },
  {
    id: "TouchdownsScored",
    pointsPer: 6,
  },
  {
    id: "FumblesLost",
    pointsPer: -2,
  },
  {
    id: "Safeties",
    pointsPer: 2,
  },
  {
    id: "BlockedKicks",
    pointsPer: 2,
  },
  {
    id: "BlockedKicks",
    pointsPer: 2,
  },
  {
    id: "KickReturnTouchdowns",
    pointsPer: 6,
  },
  {
    id: "InterceptionReturnTouchdowns",
    pointsPer: 6,
  },
  {
    id: "FumbleReturnTouchdowns",
    pointsPer: 6,
  },
  {
    id: "FumblesRecovered",
    pointsPer: 2,
  },
  {
    id: "TwoPointConversionRuns",
    pointsPer: 2,
  },
  {
    id: "TwoPointConversionPasses",
    pointsPer: 2,
  },
  {
    id: "TwoPointConversionReceptions",
    pointsPer: 2,
  },
];

export const DEFENSE_POINTS_ALLOWED_TALLY = [
  {
    min: 0,
    max: 0,
    pointsPer: 10,
    label: "0 points allowed",
  },
  {
    min: 1,
    max: 6.99,
    pointsPer: 7,
    label: "1-6 points allowed",
  },
  {
    min: 7,
    max: 13.99,
    pointsPer: 4,
    label: "7-13 points allowed",
  },
  {
    min: 14,
    max: 20.99,
    pointsPer: 1,
    label: "14-20 points allowed",
  },
  {
    min: 21,
    max: 27.99,
    pointsPer: 0,
    label: "21-27 points allowed",
  },
  {
    min: 28,
    max: 34.99,
    pointsPer: -1,
    label: "28-34 points allowed",
  },
  {
    min: 35,
    max: 999,
    pointsPer: -4,
    label: "35+ points allowed",
  },
];

export const GAME_WEEKS = [
  {
    Week: 1,
    start: "2024-09-05T00:00:00Z",
    end: "2024-09-10T23:59:59Z",
    },
    {
    Week: 2,
    start: "2024-09-11T00:00:00Z",
    end: "2024-09-17T23:59:59Z",
    },
    {
    Week: 3,
    start: "2024-09-18T00:00:00Z",
    end: "2024-09-24T23:59:59Z",
    },
    {
    Week: 4,
    start: "2024-09-25T00:00:00Z",
    end: "2024-10-01T23:59:59Z",
    },

    {
    Week: 5,
    start: "2024-10-02T00:00:00Z",
    end: "2024-10-08T23:59:59Z"
    },
    {
    Week: 6,
    start: "2024-10-09T00:00:00Z",
    end: "2024-10-15T23:59:59Z"
    },
    {
    Week: 7,
    start: "2024-10-16T00:00:00Z",
    end: "2024-10-22T23:59:59Z"
    },
    {
    Week: 8,
    start: "2024-10-23T00:00:00Z",
    end: "2024-10-29T23:59:59Z"
    },
    {
    Week: 9,
    start: "2024-10-30T00:00:00Z",
    end: "2024-11-05T23:59:59Z"
    },
    {
    Week: 10,
    start: "2024-11-06T00:00:00Z",
    end: "2024-11-12T23:59:59Z"
    },
    {
    Week: 11,
    start: "2024-11-13T00:00:00Z",
    end: "2024-11-19T23:59:59Z"
    },
    {
    Week: 12,
    start: "2024-11-20T00:00:00Z",
    end: "2024-11-26T23:59:59Z"
    },
    {
    Week: 13,
    start: "2024-11-27T00:00:00Z",
    end: "2024-12-03T23:59:59Z"
    },
    {
    Week: 14,
    start: "2024-12-04T00:00:00Z",
    end: "2024-12-10T23:59:59Z"
    },
    {
    Week: 15,
    start: "2024-12-11T00:00:00Z",
    end: "2024-12-17T23:59:59Z"
    },
    {
    Week: 16,
    start: "2024-12-18T00:00:00Z",
    end: "2024-12-24T23:59:59Z"
    },
    {
    Week: 17,
    start: "2024-12-25T00:00:00Z",
    end: "2025-12-31T23:59:59Z"
    },
    {
    Week: 18,
    start: "2025-01-01T00:00:00Z",
    end: "2025-01-07T23:59:59Z"
    }
]

export const NFL_TEAM_LOGOS = {
  Cardinals: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Cardinals.png",
  Falcons: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Falcons.png",
  Ravens: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Ravens.png",
  Bills: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bills.png",
  Panthers: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Panthers.png",
  Bears: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bears.png",
  Bengals: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Bengals.png",
  Browns: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Browns.png",
  Cowboys: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Cowboys.png",
  Broncos: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Broncos.png",
  Lions: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Lions.png",
  Packers: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Packers.png",
  Texans: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Texans.png",
  Colts: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Colts.png",
  Jaguars: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Jaguars.png",
  Chiefs: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Chiefs.png",
  Raiders: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Raiders.png",
  Rams: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Rams.png",
  Dolphins: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Dolphins.png",
  Vikings: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Vikings.png",
  Patriots: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Patriots.png",
  Saints: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Saints.png",
  Giants: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Giants.png",
  Jets: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Jets.png",
  Eagles: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Eagles.png",
  Steelers: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Steelers.png",
  Niners: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/49ers.png",
  Seahawks: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Seahawks.png",
  Buccaneers: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Buccaneers.png",
  Titans: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Titans.png",
  Commanders: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Commanders.png",
  Chargers: "https://cryptosportscards.s3.amazonaws.com/cryptosportsnfl2023/Chargers.png"
};

export const minicampLinesetting = {
  lineupBudget: 500,
  lineupSlotCounts: { QB: 1, RB: 2, TE: 1, WR: 2, DST: 1, FLEX: 1 },
};

export const USER_REFERRAL_TYPE = "USER_REFERRAL";
export const REFERRED_REWARD_TYPE = "REFERRED_REWARD";
export const REFERRAL_REWARD_AMOUNT = 100;